import styled from 'styled-components'

const Container = styled.div`
  display: ${props => props.display || 'block'};
  max-width: 1260px;
  margin: 0 auto;
  background: ${props => props.bg || 'unset'};
  align-items: ${props => props.alignItems || 'flex-start'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  grid-template-columns: ${props => props.gridTemplateColumn || '1fr'};
`

export default Container
