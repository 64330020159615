import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'

import Button from '../Button/Button'
import { color } from '../shared/styles'

const Wrapper = styled.div`
  background: ${props => props.overlayColor || 'linear-gradient(0deg, rgba(44, 130, 63, 0.7), rgba(44, 130, 63, 0.7))'}, url(${props => props.bgImgUrl || ''});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: ${props => props.isBgFixed ? 'fixed' : 'unset'};
  width: 100%;
  height: 100%;
  
  > a, > div {
    padding: 90px 10px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 106px;
    text-decoration: none;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    max-width: 1260px;
  
    &:hover {
      button {
        background: ${props => color[props.variant] || color.white};
        color: ${color.green};
        cursor: pointer;
      }
    }
  
    p {
      font-size: 36px;
      color: ${color.white};
      font-weight: bolder;
      line-height: 58px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 1160px) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
      padding: 60px 120px;

      > div {
        text-align: center;
      }
    }

    @media screen and (min-width: 468px) and (max-width: 899.99999px) {
      padding: 60px;
    }

    @media screen and (max-width: 467.99999px) {
      padding: 30px;
    }
  }

  > div {
    grid-template-columns: 1fr;
    text-align: center;

    @media screen and (min-width: 1160px) {
      max-width: 660px;
    }
  } 
`

const CallToAction = ({ bgImgUrl, height, text, to, buttonText, isBgFixed, overlayColor }) => {
  return (
    <Wrapper
      bgImgUrl={bgImgUrl}
      height={height}
      isBgFixed={isBgFixed}
      overlayColor={overlayColor}
    >
      {!to ? (
        <div>
          <p>{text}</p>
          {buttonText && (
            <Button onClick={() => navigate("/contacto")} variant="white">{`${buttonText}`}</Button>
          )}
        </div>
      ) : (
        <Link to={to}>
          <p>{text}</p>
          {buttonText && (
            <Button onClick={() => navigate("/contacto")} variant="white">{`${buttonText}`}</Button>
          )}
        </Link>
      )}
    </Wrapper>
  )
}

CallToAction.propTypes = {
  bgImgUrl: PropTypes.string,
  buttonText: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  isBgFixed: PropTypes.bool,
}

CallToAction.defaultProps = {
  bgImgUrl: '',
  buttonText: '',
  height: 'max-content',
  text: '',
  to: '',
  isBgFixed: false,
}

export default CallToAction;
